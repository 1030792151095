/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Card,
  Chip,
  Box,
  Divider,
} from "@mui/material";
import { getFileAsBlob, postFormData } from "../../utils/api";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { calculateHourBetween2Times } from "../../utils/time";
import { Row } from "./Scaffolds";
import { ReactComponent as Cert3Logo } from "../../assets/cert3-logo.svg";
import {ReactComponent as DiplomaLogo} from "../../assets/diploma-logo.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download-icon.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/verified-icon.svg";
import { ReactComponent as IssueIcon } from "../../assets/issue-icon.svg";
import { ReactComponent as DocumentIcon } from "../../assets/document-logo.svg";
import { ReactComponent as DocumentIcon2 } from "../../assets/doc-icon-logo.svg";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as PendingIcon } from "../../assets/pending-icon.svg";
import { ReactComponent as FirstAidIcon } from "../../assets/first-aid-icon.svg";
import { ReactComponent as ChildProtectionIcon } from "../../assets/child-care-icon.svg";
import theme from "../../utils/theme";
import { FullSizedButton } from "./Buttons";
import { OrgContext, UserContext } from "../../utils/context";
import { isOwner } from "../../utils/auth";
const FilePreview = ({
  fileUrl,
  title,
  document,
  verifyCallback,
  sx,
  imgStyle,
  personID,
  isOptional,
  documentType,
  refresh,
  isShowDocumentButton = true,
}) => {
  
  const [fileSrc, setFileSrc] = useState("");
  const fileInputRef = React.createRef();
  const previewStyle = {
    width: "100%",
    height: "150px",
    textAlign: "left",
    border: "1px solid #ccc",
    ...imgStyle,
  };
  const currentUser = useContext(UserContext)
  const currentOrg = useContext(OrgContext)
  const canSave = currentUser?.is_quickcare_admin || isOwner(currentOrg)
  const orgId = currentUser?.is_quickcare_admin? 0: currentUser?.organisations[0].organisation_id
  const fetchFile = 
    async (url) => {
      try {
        const fileSrc = await getFileAsBlob(fileUrl);
        setFileSrc(fileSrc);
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    }
   

  const handleDownload = () => {
    const link = window.document.createElement("a");
    link.href = fileSrc;
    link.download = fileUrl ? fileUrl.split("/").pop() : "downloaded-file";
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
  };

  const isVerifingStuck = useCallback(() => {
    if(!document?.updated_at) {
      return false
    }
    return calculateHourBetween2Times(new Date(document.updated_at), new Date()) > 4 && document.verified_status === 'verifying'
  },[document?.updated_at, document?.verified_status])

  useEffect(() => {
    if (
      !fileUrl.endsWith("undefined") &&
      !fileUrl.endsWith("null") &&
      !fileUrl.endsWith("=")
    ) {
      fetchFile(fileUrl);
    }
    if(isVerifingStuck()) {
      document.verified_status = 'manual_required'
      document.message = 'Stuck verifying from Collabra for more than 4 hours'
    }
  }, []);

  
   const validFile =
     fileSrc &&
     !fileUrl.endsWith("undefined") &&
     !fileUrl.endsWith("null") &&
     !fileUrl.endsWith("=");  
     
  const getButtonType = (status) => {
    switch (status) {
      case "success":
        return "primaryContainer";
      case "manual_required":
      case "failed":
        return "tertiary";
      default:
        if (isOptional) {
          return "secondary";
        } else {
          return "primaryContainer";
        }
    }
  };
  const getButtonTitle = (status) => {
    switch (status) {
      case "manual_required":
      case "failed":
        return "Resolve";
      case "success":
      case "verifying":
        return "Update Document";
      default:
        return "Add Document";
    }
  };
  const getIcon = (status) => {
    switch (status) {
      case "manual_required":
      case "failed":
        return (
          <EditIcon
            style={{
              fill: "inherit",
            }}
          />
        );
      case "success":
        return (
          <DocumentIcon
            style={{
              fill: "inherit",
            }}
          />
        );
      default:
        return (
          <DocumentIcon
            style={{
              fill: "inherit",
            }}
          />
        );
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const currentTimestamp = new Date().getTime();
    const updatedFileName = `${documentType}.${currentTimestamp}.${file.name
      .split(".")
      .pop()}`;
    updateQualificationFile(file, updatedFileName );
  };
  const updateQualificationFile = async (updatedFile,updatedFileName) => {
    if (!updatedFile) {
      return;
    }
    const fileName = updatedFileName;
    const formData = new FormData();
    formData.append('file', updatedFile);
    try {
      const response = await postFormData(`/onboarding-upload?fileName=${fileName}&educator_id=${personID}&org_id=${orgId}`, formData,
        {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        }
      );
      refresh()
      return response
    } catch (error) {
      throw error;
    }
  }
  const isPDFDocument = (fileUrl) => {
    const fileExtension = fileUrl.split('.').pop();
    return fileExtension.toLowerCase() === 'pdf';
  }
  return (
    <Grid item xs={6}>
      <Card
        sx={{
          ...sx,
          borderRadius: "10px",
          backgroundColor: theme.palette.surface.main,
        }}
      >
        <TitleSection
          title={title}
          isShowDocumentButton={isShowDocumentButton}
          handleDownload={handleDownload}
          isOptional={isOptional}
          document={document}
        />
        <Box sx={{ paddingX: 1 }}>
          {validFile && (
            <Box
              sx={{
                height: "120px",
                overflow: "hidden",
                position: "relative",
                borderRadius: "10px",
                marginTop: "10px",
              }}
            >
              {isPDFDocument(fileUrl) ? (
                <Worker workerUrl="/pdf.worker.min.js">
                  <Viewer fileUrl={fileSrc} initialPage={0} />
                </Worker>
              ) : (
                <img src={fileSrc} alt={title} style={previewStyle} />
              )}
            </Box>
          )}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          {(isShowDocumentButton && canSave) && (
            <FullSizedButton
              type={getButtonType(isOptional ? "optional" : document?.verified_status)}
              onClick={() => {
                if(validFile) {
                  verifyCallback(fileSrc)
                } else {
                  fileInputRef.current.click();
                }
              }}
              text={getButtonTitle(document?.verified_status)}
              icon={getIcon(document?.verified_status)}
            ></FullSizedButton>
          )}
        </Box>
      </Card>
    </Grid>
  );
};
function TitleSection({ title, handleDownload, document, isOptional, isShowDocumentButton }) {
  const iconStyle = {
    width: "24px",
    height: "24px",
  };
  const getIcon = () => {
    switch (title) {
      case "Qualification or Enrolment":
      case "Previous Qualification":
        return (
          <Cert3Logo style={iconStyle} />
        );
      case "Resume / CV":
        return (
          <DocumentIcon2 style={iconStyle} />
        );
      case "Transcript":
        return (
          <DiplomaLogo style={iconStyle} />
        );
      case "CPR Certificate":
      case "First Aid":
        return (
          <FirstAidIcon style={iconStyle} />
        );
      case "Child Protection":
        return (
          <ChildProtectionIcon style={iconStyle} />
        );
      default:
        return (
          <DocumentIcon style={iconStyle} />
        );
    }
  }
  if (!title) {
    return null;
  }
  return (
    <>
      <Row
        sx={{
          padding: 1,
        }}
      >
        {getIcon()}
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 400,
            marginLeft: "4px",
            marginRight: "auto",
          }}
        >
          {title}
        </Typography>
        {(document && (!isOptional || document?.verified_status === 'manual_required')) ? (
          <DocumentStatusTag
            status={document?.verified_status}
            message={document?.message}
          />
        ) : (
          <DocumentMandatoryTag isOptional={isOptional} hidden={!isShowDocumentButton} />
        )}
        {(document || !isShowDocumentButton) && (
          <IconButton
            color="info"
            aria-label="download"
            onClick={handleDownload}
            sx={{ cursor: "pointer" }}
          >
            <DownloadIcon />
          </IconButton>
        )}
      </Row>
      <Divider />
    </>
  );
}
function DocumentMandatoryTag({ isOptional, hidden }) {
  if (hidden) {
    return null;
  }
  return (
    <Chip
      label={isOptional ? "Optional" : "Required"}
      sx={{
        backgroundColor: isOptional
          ? theme.palette.secondary.main
          : theme.palette.errorContainer.main,
        color: isOptional
          ? theme.palette.secondary.onSecondary
          : theme.palette.errorContainer.onError,
      }}
      size="small"
    ></Chip>
  );
}
function DocumentStatusTag({ status, message }) {
  const statusColor = {
    manual_required: "#FFC300",
    success: "green",
    failed: "#E4252E",
    rejected: "#E4252E",
    verifying: "#ed6c02",
  };
  if (status=== 'success') {
    return (
      <VerifiedIcon />
    );
  }
  else if (status === 'verifying') {
    return (
      <PendingIcon />
    );
  }
  else if ((status === 'manual_required' || status === 'failed') && message) {
    return (
      <Chip
        label={message}
        sx={{
          backgroundColor: statusColor.failed,
          color: "white",
        }}
        size="small"
        icon={<IssueIcon sx={{ fill: "white" }}></IssueIcon>}
      >
      </Chip>
    );
  }
  return null;
}
export default FilePreview;
