/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { get, post } from "../../../../../utils/api";
import { SuccessDialogModel } from "../../../../reusable/Modal";
import { BackButton } from "../../../../reusable/Buttons";
import { PaddingBox } from "../../../../reusable/Scaffolds";
import InfoTab from "./info_tab";
import CertTab from "./cert_tab";
import { CommonTextField } from "../../../../reusable/TextField";
import { Row } from "../../../../Layout";




const MemberDetail = ({ setAlert }) => {
  const { member_id } = useParams();
  const [member, setMember] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadingComment, setLoadingComment] = useState(false);
  const [comment, setComment] = useState("");
  useState(false);
  const [successTitle, setSuccessTitle] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [content, setContent] = useState("");
  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate("/applications");
  };
  

  const loadMemberIdentityLink = useCallback(async () => {
    try {
      const identityLink = await get(`/admin/onboarding/identity/${member_id}`);
      return identityLink;
    } catch (error) {
      return null;
    }
  }, [member_id]);

  const loadMember = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/admin/onboarding/${member_id}`);
      let avatar = null;
      if (response.avatar_url) {
        avatar = response.avatar_url;
      }
      const identityLink = await loadMemberIdentityLink();
      setMember({ ...response, avatar, identityLink });
      setComment(response.comment);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to fetch members:", error);
    }
  };

  const updateUserComment = async () => {
    setLoadingComment(true);
    await post(`/admin/onboarding/comment`, {
      id: member_id,
      comment,
    })
      .then(() => {
        loadMember();
        setAlert("Comment updated successfully", "success");
        setLoadingComment(false);
        setComment(comment);
      })
      .catch((error) => {
        setAlert(error.message, "error");
        setLoadingComment(false);
      });
  };


  const inviteQuickcare = async () => {
    setIsLoading(true);
    await post(`/manage/organisations/0/people`, {
      email: member.email,
      phone_number: member.phone_number,
      access: "educator",
    })
      .then((res) => {
        loadMember();
        if (res.content) {
          setContent(res.content);
        }
        setShowSuccess(true);
        setIsLoading(false);
      })
      .catch((error) => {
        loadMember();
        setAlert(error.message, "error");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadMember();
  }, []);

  return (
    <>
      <Box sx={{ mx: "auto", p: 3, marginTop: "-50px" }}>
        <BackButton
          title="Back to Members"
          onClick={handleBackButton}
        ></BackButton>
        <PaddingBox></PaddingBox>
        <InfoTab
          member={member}
          isLoading={isLoading}
          inviteQuickcare={inviteQuickcare}
        />
        <Divider />
        <PaddingBox></PaddingBox>
        <Box sx={{ textAlign: "left", display: isLoading ? "none" : "flex" }}>
          <Typography variant="h6">Comment</Typography>
        </Box>

        <PaddingBox></PaddingBox>
        <Row
          sx={{
            display: isLoading ? "none" : "flex",
          }}
        >
          <CommonTextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={updateUserComment}
            disabled={loadingComment || comment === member.comment}
            sx={{ height: 40, ml: 2 }}
          >
            {loadingComment ? <CircularProgress size={20} /> : "Save"}
          </Button>
        </Row>
        <PaddingBox />
        <Box sx={{ textAlign: "left" }}>
          <Typography variant="h6">Documents</Typography>
        </Box>
        <PaddingBox />
        {isLoading ? (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <CertTab
            member={member}
            refresh={loadMember}
            user_info_id={member.id}
            person_id={member.person_id}
          />
        )}
        <SuccessDialogModel
          onClose={() => {
            setSuccessTitle(null);
            setShowSuccess(false);
          }}
          open={showSuccess}
          title={successTitle ?? "New login created"}
          description={`<p>An email and SMS has been sent to the user informing them of this invitation.</p><p>${content}</p>`}
        />
      </Box>
    </>
  );
};
export default MemberDetail;
