/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { get, post } from "../../../../../utils/api";
import FilePreview from "../../../../reusable/FilePreview";
import {
  ManualRejectionModal,
  ManualVerifyModalOthers,
  ManualVerifyModalQualification,
} from "./manual_verify_popup";
const baseUrl = "/file-content?filePath=";
const CertTab = ({ member, refresh, person_id, user_info_id }) => {
  const [manualRejectionModalOpen, setManualRejectionModalOpen] =
    useState(false);
  const [manualModalOpen, setManualModalOpen] = useState(false);
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [manualModalCertTypeOpen, setManualModalCertTypeOpen] = useState(null);
  const [currentFileUrl, setCurrentFileUrl] = useState(null);
  const [currentManualDocument, setCurrentManualDocument] = useState(null);
  const [selectedFileSrc, setSelectedFileSrc] = useState(null);
  const [rejectDocId, setRejectDocId] = useState(null);

  const getRequiredDocuments = async () => {
    try {
      const response = await get(
        `/get_required_documents_by_tier?educator_id=${person_id}`
      );
      if (response) {
        setRequiredDocuments(response.required_documents);
      }
    } catch (error) {
      console.error("Error fetching required documents:", error);
    }
  };

  const rejectQualification = async (document_id, reason, document_type) => {
    try {
      if (document_type === "qualification") {
        await post("/manage/qualification/manual_rejection", {
          document_id,
          reason,
        });
      } else {
        await post("/manage/certificate/manual_rejection", {
          document_id,
          reason,
        });
      }
      refresh();
    } catch (error) {
      console.error(error);
    }
  };

  const memberQualification = member.documents?.find(
    (doc) => doc.document_type === "qualification"
  );
  if (memberQualification) {
    memberQualification.person_id = person_id;
  }
  const memberPreviousQualification = member.documents?.find(
    (doc) => doc.document_type === "previous_qualification"
  );
  const memberResume = member.documents?.find(
    (doc) => doc.document_type === "resume"
  );
  const memberCPR = member.documents?.find(
    (doc) => doc.document_type === "cpr_cert"
  );
  const memberFirstAid = member.documents?.find(
    (doc) => doc.document_type === "first_aid_cert"
  );
  const memberTranscript = member.documents?.find(
    (doc) => doc.document_type === "transcript"
  );
  const memberChildProtection = member.documents?.find(
    (doc) => doc.document_type === "child_protection_cert"
  );
  const memberFoodHandling = member.documents?.find(
    (doc) => doc.document_type === "food_handling_cert"
  );

  const memberOther = member.documents?.find(
    (doc) => doc.document_type === "other"
  );

  const memberTeacherRegistration = member.documents?.find(
    (doc) => doc.document_type === "teacher_registration"
  );
  const isEmptyCert =
    !member.resume_url &&
    !member.transcript_url &&
    !member.child_protection_cert &&
    !member.first_aid_cert &&
    !member.cpr_cert_url;

  useEffect(() => {
    getRequiredDocuments();
  }, []);
  return (
    <Grid container spacing={2}>
      <ManualRejectionModal
        open={manualRejectionModalOpen}
        onClose={() => setManualRejectionModalOpen(false)}
        onReject={(reason) => {
          rejectQualification(
            rejectDocId,
            reason,
            currentManualDocument.document_type
          );
        }}
      />
      <ManualVerifyModalQualification
        open={manualModalOpen}
        onClose={(preventRefresh) => {
          if (!preventRefresh) {
            refresh();
          }
          setManualModalOpen(false);
        }}
        rejectQualification={(document_id) => {
          setRejectDocId(document_id);
          setManualRejectionModalOpen(true);
        }}
        document={currentManualDocument}
        personID={person_id}
        fileUrl={currentFileUrl}
        fileSrc={selectedFileSrc}
      />
      <ManualVerifyModalOthers
        open={manualModalCertTypeOpen}
        onClose={(preventRefresh) => {
          if (!preventRefresh) {
            refresh();
          }
          setManualModalCertTypeOpen(null);
        }}
        rejectDocument={(document_id) => {
          setRejectDocId(document_id);
          setManualRejectionModalOpen(true);
        }}
        document={currentManualDocument}
        personID={person_id}
        userInfoId={user_info_id}
        fileUrl={currentFileUrl}
        fileSrc={selectedFileSrc}
      />
      <FilePreview
        fileUrl={`${baseUrl}${member.qualification_url}`}
        title="Qualification or Enrolment"
        documentType={"qualification"}
        document={memberQualification}
        isOptional={!requiredDocuments?.includes("qualification")}
        refresh={refresh}
        personID={person_id}
        rejectQualification={(document_id) => {
          setRejectDocId(document_id);
          setManualRejectionModalOpen(true);
        }}
        verifyCallback={(fileSrc) => {
          setSelectedFileSrc(fileSrc);
          setCurrentManualDocument(memberQualification);
          setCurrentFileUrl(`${baseUrl}${member.qualification_url}`);
          setManualModalOpen(true);
        }}
      />
      <FilePreview
        fileUrl={`${baseUrl}${member.previous_qualification_url}`}
        title="Previous Qualification"
        documentType={"previous_qualification"}
        isOptional={!requiredDocuments?.includes("previous_qualification")}
        document={memberPreviousQualification}
        refresh={refresh}
        personID={person_id}
        rejectQualification={(document_id) => {
          setRejectDocId(document_id);
          setManualRejectionModalOpen(true);
        }}
        verifyCallback={(fileSrc) => {
          setSelectedFileSrc(fileSrc);
          setCurrentManualDocument(memberPreviousQualification);
          setCurrentFileUrl(`${baseUrl}${member.previous_qualification_url}`);
          setManualModalCertTypeOpen(true);
        }}
      />
      <FilePreview
        fileUrl={`${baseUrl}${member.resume_url}`}
        title="Resume / CV"
        documentType={"resume"}
        isOptional={!requiredDocuments?.includes("resume")}
        refresh={refresh}
        personID={person_id}
        verifyCallback={(fileSrc) => {
          setSelectedFileSrc(fileSrc);
          setCurrentManualDocument(memberResume);
          setCurrentFileUrl(`${baseUrl}${member.resume_url}`);
          setManualModalCertTypeOpen(true);
        }}
        document={memberResume}
      />

      <FilePreview
        fileUrl={`${baseUrl}${member.transcript_url}`}
        refresh={refresh}
        isOptional={!requiredDocuments?.includes("transcript")}
        title="Transcript"
        document={memberTranscript}
        documentType={"transcript"}
        personID={person_id}
        verifyCallback={(fileSrc) => {
          setSelectedFileSrc(fileSrc);
          setCurrentManualDocument(memberTranscript);
          setCurrentFileUrl(`${baseUrl}${member.transcript_url}`);
          setManualModalCertTypeOpen(true);
        }}
      />
      <FilePreview
        fileUrl={`${baseUrl}${member.cpr_cert_url}`}
        refresh={refresh}
        title="CPR Certificate"
        isOptional={!requiredDocuments?.includes("cpr_cert")}
        personID={person_id}
        documentType={"cpr_cert"}
        document={memberCPR}
        verifyCallback={(fileSrc) => {
          setSelectedFileSrc(fileSrc);
          setCurrentManualDocument(memberCPR);
          setCurrentFileUrl(`${baseUrl}${member.cpr_cert_url}`);
          setManualModalCertTypeOpen(true);
        }}
      />
      <FilePreview
        fileUrl={`${baseUrl}${member.first_aid_cert}`}
        refresh={refresh}
        title="First Aid"
        isOptional={true}
        documentType="first_aid_cert"
        personID={person_id}
        verifyCallback={(fileSrc) => {
          setSelectedFileSrc(fileSrc);
          setCurrentManualDocument(memberFirstAid);
          setCurrentFileUrl(`${baseUrl}${member.first_aid_cert}`);
          setManualModalCertTypeOpen(true);
        }}
        document={memberFirstAid}
      />
      <FilePreview
        fileUrl={`${baseUrl}${member.child_protection_cert}`}
        refresh={refresh}
        title="Child Protection"
        isOptional={!requiredDocuments?.includes("child_protection_cert")}
        personID={person_id}
        documentType="child_protection_cert"
        document={memberChildProtection}
        verifyCallback={(fileSrc) => {
          setSelectedFileSrc(fileSrc);
          setCurrentManualDocument(memberChildProtection);
          setCurrentFileUrl(`${baseUrl}${member.child_protection_cert}`);
          setManualModalCertTypeOpen(true);
        }}
      />
      <FilePreview
        fileUrl={`${baseUrl}${member.food_handling_cert_url}`}
        refresh={refresh}
        title="Food Handling"
        isOptional={!requiredDocuments?.includes("food_handling_cert")}
        personID={person_id}
        documentType="food_handling_cert"
        document={memberFoodHandling}
        verifyCallback={(fileSrc) => {
          setSelectedFileSrc(fileSrc);
          setCurrentManualDocument(memberFoodHandling);
          setCurrentFileUrl(`${baseUrl}${member.food_handling_cert_url}`);
          setManualModalCertTypeOpen(true);
        }}
      />
      <FilePreview
        fileUrl={`${baseUrl}${member.other_url}`}
        refresh={refresh}
        title="Other document"
        isOptional={!requiredDocuments?.includes("other")}
        personID={person_id}
        documentType="other"
        document={memberOther}
        verifyCallback={(fileSrc) => {
          setSelectedFileSrc(fileSrc);
          setCurrentManualDocument(memberOther);
          setCurrentFileUrl(`${baseUrl}${member.other_url}`);
          setManualModalCertTypeOpen(true);
        }}
      />
      <FilePreview
        fileUrl={`${baseUrl}${member.teacher_registration_url}`}
        refresh={refresh}
        title="Teacher Registration"
        isOptional={!requiredDocuments?.includes("teacher_registration")}
        personID={person_id}
        documentType="teacher_registration"
        document={memberTeacherRegistration}
        verifyCallback={(fileSrc) => {
          setSelectedFileSrc(fileSrc);
          setCurrentManualDocument(memberTeacherRegistration);
          setCurrentFileUrl(`${baseUrl}${member.teacher_registration_url}`);
          setManualModalCertTypeOpen(true);
        }}
      />
      {isEmptyCert && (
        // align center
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <p>No certificates uploaded</p>
        </Grid>
      )}
    </Grid>
  );
};

export default CertTab;
