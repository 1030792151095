import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import { CustomAvatar } from "../../../Layout";
import { formatTimestampToddmmyyyy } from "../../../../utils/time";
import { extractSuburb, extractState, stringAvatar } from "../../../../utils/helper_functions";
import { AddButton } from "../../Buttons";
import Video from "../../video";

export function InfoProfileUser({ member, showInviteButton, inviteQuickcare }) {
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openToMap = {
    fullTime: "Full time",
    partTime: "Part time",
    casual: "Casual",
    childMinding: "Child Minding",
  };
  const ageGroupMap = {
    "0_24_months": " 0 - 24 Months",
    "2_3_years": "2 - 3 Years",
    "4_6_years": "3 - 6 Years",
    oosh: "OOSH",
  };
  const noticePeriodMap = {
    "0_2_weeks": "0 - 2 Weeks",
    "2_4_weeks": "2 - 4 Weeks",
    "4_plus_weeks": "4+ Weeks",
  };
  const getOpenTo = (openTo) => {
    return openTo
      ?.map((item) => (openToMap[item] ? openToMap[item] : ""))
      .filter((item) => item !== "")
      .join(", ");
  };

  const getAgeGroup = (ageGroup) => {
    return ageGroup
      ?.map((item) => (ageGroupMap[item] ? ageGroupMap[item] : ""))
      .filter((item) => item !== "")
      .join(", ");
  };

  const getNoticePeriod = (noticePeriod) => {
    return noticePeriodMap[noticePeriod] ? noticePeriodMap[noticePeriod] : "";
  };
  const getWWCCData = (wwcc_data) => {
    if (wwcc_data && Array.isArray(wwcc_data)) {
      return wwcc_data
        .map((entry) => `${entry.wwcc_number} - ${entry.state}`)
        .join(",  ");
    }
    return "";
  };
  const isCompleteStatus = () => {
    return (
      member.prefered_name &&
      member.identity_verified &&
      member.is_verify_wwcc &&
      member.is_verify_qualification &&
      member.video_url
    );
  };

  const profileDetailStyles = {
    label: {
      width: 150,
      fontWeight: "bold",
      textAlign: "right",
      marginRight: "10px",
      fontSize: "13px",
    },
    value: { marginLeft: "10px", fontSize: "13px" },
    container: { display: "flex" },
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
        <CustomAvatar
          {...stringAvatar(member.first_name + " " + member.last_name)}
          src={member.avatar !== null ? member.avatar : null}
          sx={{
            width: 120,
            height: 120,
            mx: "auto",
            borderRadius: "50%",
          }}
        />
        <Button
          variant="contained"
          onClick={openModal}
          startIcon={<VideoCameraFrontIcon />}
          disabled={!member.video_url}
          sx={{ mt: 2, backgroundColor: "#7A4FFF" }}
        >
          Short video
        </Button>
        <Video
          videoUrl={member.video_url}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1}>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>Rego State:</Typography>
            <Chip
              label={isCompleteStatus() ? "Complete" : "Verifying"}
              size="small"
              sx={{
                backgroundColor: isCompleteStatus() ? "#93dcce" : "#ed6c02",
              }}
            />
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>
              Preferred Name:
            </Typography>
            <Typography sx={profileDetailStyles.value}>
              {member.prefered_name}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>First Name:</Typography>
            <Typography sx={profileDetailStyles.value}>
              {member.first_name}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>Last Name:</Typography>
            <Typography sx={profileDetailStyles.value}>
              {member.last_name}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>Person ID:</Typography>
            <Typography sx={profileDetailStyles.value}>
              {member.person_id}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>Suburb:</Typography>
            <Typography sx={profileDetailStyles.value}>{`${extractSuburb(
              member.address
            )} ${extractState(member.address)}`}</Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>Email:</Typography>
            <Typography sx={profileDetailStyles.value}>
              {member.email}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>
              Phone Number:
            </Typography>
            <Typography sx={profileDetailStyles.value}>
              {member.phone_number}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>
              Date of birth:
            </Typography>
            <Typography sx={profileDetailStyles.value}>
              {formatTimestampToddmmyyyy(member.date_of_birth)}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>
              Australian Citizen:
            </Typography>
            <Typography sx={profileDetailStyles.value}>
              {member.is_aus_citizen ? "Yes" : "No"}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>Open to:</Typography>
            <Typography sx={profileDetailStyles.value}>
              {getOpenTo(member.open_to)}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>
              Preferred age groups:
            </Typography>
            <Typography sx={profileDetailStyles.value}>
              {getAgeGroup(member.age_group)}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>
              Notice period:
            </Typography>
            <Typography sx={profileDetailStyles.value}>
              {getNoticePeriod(member.notice_period)}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>
              Distance willing to work:
            </Typography>
            <Typography sx={profileDetailStyles.value}>
              {member.distance}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>WWCC Number:</Typography>
            <Typography sx={{ ...profileDetailStyles.value, width: "70%" }}>
              {getWWCCData(member.wwcc_data)}
            </Typography>
          </Box>
          <Box sx={profileDetailStyles.container}>
            <Typography sx={profileDetailStyles.label}>VEVO check:</Typography>
            <Typography sx={profileDetailStyles.value}>
              {member.vevo_check}
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={2}>
        {showInviteButton ? (
          <AddButton
            onClick={inviteQuickcare}
            text="Approved to Join QC"
            disabled={member?.step !== "profile" && member?.step !== "status"}
            theme={theme}
          ></AddButton>
        ) : null}
      </Grid>
      <Grid spacing={2} item xs={3} container>
        <Grid container item xs={12}>
          <Typography variant="h6">Photo ID</Typography>
        </Grid>
        <Grid container spacing={2} item xs={6}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Front</Typography>
            {member.identityLink && (
              <img
                src={member.identityLink}
                alt={"Front"}
                style={{
                  borderRadius: 8,
                  width: "200px",
                  height: "150px",
                  textAlign: "left",
                  border: "1px solid #ccc",
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}