

import {
  Box,
  CircularProgress,
} from "@mui/material";
import { InfoProfileUser } from "../../../../reusable/ui_components/educator/educator_profile";

const InfoTab = ({ member, isLoading, inviteQuickcare }) => {
  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", mt: 5, mb: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  return <InfoProfileUser member={member} inviteQuickcare={inviteQuickcare} />;
};

export default InfoTab;
