/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { SearchBox } from "../../reusable/TextField";
import { get, getFileAsBlob } from "../../../utils/api";
import { debounce } from "../../../utils/helper_functions";
import {
  PaddingBox,
  SpacedRow,
} from "../../reusable/Scaffolds";
import MemberList from "./components/MemberTab";
import { ApplicationReviewModal } from "./components/application_review_modal";
import { Box } from "@mui/material";
import { M3TitleLarge } from "../../reusable/TextStyles";
import { FilterButton } from "../../reusable/Buttons";
import { documentStatusFilter } from "../../../utils/constants";
const ApplicationsManage = ({ setAlert }) => {
  const baseUrl = "/file-content?filePath=";
  const NONE = 'None'
  const QUICKCARE = "QC"
  const ALL = "All"

  const [documentStatus, setDocumentStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = React.useState(NONE);

  const fetchOrganisations = useCallback(async () => {
    const res = await get(`/admin/organisations`);
    const organisationList = res.map(org => ({ id: org.id, name: org.name }))
    setOrganisationOptions(organisationList)
  }, []);
  const handleSelectedOrganisationChange = (event) => {
    const selectedOrg = event.target.value;
    setSelectedOrganisation(selectedOrg);
  }

  const calculatePageSize = () => {
    const itemHeight = 100;
    const screenHeight = window.innerHeight;
    const size = Math.floor(screenHeight / itemHeight);
    return size < 10 ? 10 : size;
  };

  const fetchMembers = useCallback(
    async (pageIndex, hasMoreItem, current_search_text) => {
      const currentPage = pageIndex || page;
      const currentHasMore = hasMoreItem || hasMore;
      const currentText = current_search_text ?? searchText;
      if (loading || !currentHasMore) return;
      setLoading(true);
      try {
        const pageSize = calculatePageSize();
        const response = await get(
          `/admin/onboarding?page=${currentPage}&page_size=${pageSize}&search_text=${
            currentText || ""
          }&document_status=${documentStatus || ""}&organisation_id=${selectedOrganisation}`
        );
        response.forEach(async (member) => {
          member.avatar = await getFileAsBlob(`${baseUrl}${member.photo_url}`);
        });
        if (currentPage === 1) {
          setMembers(response);
        } else {
          setMembers((prevMembers) => [...prevMembers, ...response]);
        }
        setHasMore(response.length > 0);
      } catch (error) {
        console.error("Failed to fetch members:", error);
      } finally {
        setLoading(false);
      }
    },
    [page, hasMore, searchText, loading, documentStatus, selectedOrganisation]
  );
  const debouncedFetchApplications = (searchText) => {
    debounce(loadMembers(searchText), 200);
  };

  const onClickItem = (application) => {
    setSelectedApplication(application);
  };

  const loadMembers = (searchText) => {
    setPage(1);
    setHasMore(true);
    fetchMembers(1, true, searchText);
  };
  const closeReviewModal = () => {
    setSelectedApplication(null);
  };
  useEffect(() => {
    fetchOrganisations();
  }, [fetchOrganisations]);
  useEffect(() => {
    loadMembers();
  }, [documentStatus]);
  useEffect(() => {
    loadMembers(searchText);
  }
  , [selectedOrganisation, searchText]);
  useEffect(() => {
    fetchMembers(page, null, null);
  }, [page]);
  return (
    <div>
      <ApplicationReviewModal
        open={selectedApplication !== null}
        closeModal={closeReviewModal}
        people={selectedApplication}
        setAlert={setAlert}
      />
      <SpacedRow>
        <M3TitleLarge>Applications</M3TitleLarge>
      </SpacedRow>
      <PaddingBox />

      <Box sx={{ padding: "20px 0" }}>
        <SearchBox
          placeholder={"Search for an educator"}
          onChange={(e) => {
            setSearchText(e.target.value);
            debouncedFetchApplications(e.target.value);
          }}
          value={searchText}
        ></SearchBox>
        <FilterButton
          currentValue={documentStatus}
          onChange={(e) => {
            setDocumentStatus(e.target.value);
          }}
          placeholder={"Document status"}
          options={documentStatusFilter}
        ></FilterButton>
        <FilterButton
          currentValue={selectedOrganisation}
          onChange={handleSelectedOrganisationChange}
          placeholder={"Select Organisation"}
          options={[
            { id: NONE, name: "None" },
            {
              id: ALL,
              name: "All",
            },
            {
              id: QUICKCARE,
              name: "QuickCare",
            },
            ...organisationOptions,
          ]}
        ></FilterButton>
      </Box>
      <MemberList
        members={members}
        loading={loading}
        hasMore={hasMore}
        page={page}
        setPage={setPage}
        onClickItem={onClickItem}
      />
    </div>
  );
};

export default ApplicationsManage;
