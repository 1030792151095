/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as RatingIcon } from "../../../assets/rating.svg";
import { get, getFileAsBlob, post, put } from "../../../utils/api";
import { debounce } from "../../../utils/helper_functions";
import theme from "../../../utils/theme";
import { compareTwoDates, isValidDate } from "../../../utils/time";
import { CustomAvatar } from "../../Layout";
import {
  BackButton,
  DeactiveAndActiveButton,
  PrimaryButton,
  RemoveButton,
  SuccessButton,
} from "../../reusable/Buttons";
import { EducatorShiftItemList } from "../../reusable/List";
import { ErrorDialogModal } from "../../reusable/Modal";
import {
  CustomTab,
  CustomTabs,
  PaddingBox,
  Row,
  Space,
  SpacedRow,
} from "../../reusable/Scaffolds";
import { Tags } from "../../reusable/Tags";
import { DatePickerTextField } from "../../reusable/TextField";
import {
  M3BodyMedium,
  M3TitleLarge,
  M3TitleMedium,
} from "../../reusable/TextStyles";
import CertTab from "../Applications/components/MemberDetail/cert_tab";
import {
  ManualRejectionModal,
  ManualVerifyModalQualification,
} from "../Applications/components/MemberDetail/manual_verify_popup";
import { InfoProfileUser } from "../../reusable/ui_components/educator/educator_profile";

const quickcareEducator = 7;
const EducatorDetail = ({ currentUser }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentTab, setCurrentTab] = React.useState(0);
  const [manualModalOpen, setManualModalOpen] = useState(false);
  const [manualRejectionModalOpen, setManualRejectionModalOpen] =
    useState(false);
  const [educatorInfo, setEducatorInfo] = React.useState(null);
  const [fileSrc, setFileSrc] = React.useState(null);
  const [shifts, setShifts] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [activeDialogOpen, setActiveDialogOpen] = useState(false);
  const [organisationsActive, setOrganisationsActive] = useState([]);
  const [organisationsDeactive, setOrganisationsDeactive] = useState([]);

  const handleOpenPopup = () => {
    setManualModalOpen(true);
  };

  const getTodayDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  function getMaxDate() {
    if (currentTab === 1 || currentTab === 2) {
      return null;
    }
    return getTodayDate();
  }

  function getMinDate() {
    if (currentTab === 0 || currentTab === 3 || currentTab === 4) {
      return null;
    }
    const todayDate = getTodayDate();
    return todayDate;
  }

  const navigateToShiftDetail = (shift_id) => {
    navigate(`/shifts/${shift_id}?from=educator_detail`);
  };

  const handleTabChange = (event, newValue) => {
    debounce(setCurrentTab(newValue), 500);
  };

  function decideTagType(educatorInfo) {
    if (educatorInfo && educatorInfo.available) {
      return 6;
    } else return 5;
  }

  const fetchShift = useCallback(async () => {
    if (
      !id ||
      startDate.toString().includes("Invalid") ||
      endDate.toString().includes("Invalid")
    )
      return;
    setIsLoading(true);
    try {
      const orgUrl = currentUser?.is_quickcare_admin
        ? `/admin/educator/${id}/shifts`
        : `/manage/organisations/${currentUser?.organisations[0].organisation_id}/educator/${id}/shifts`;
      const res = await get(orgUrl, null, {
        start_date: startDate,
        end_date: endDate,
      });
      setShifts(res);
    } catch (e) {}
    setIsLoading(false);
  }, [
    id,
    startDate,
    endDate,
    currentUser?.is_quickcare_admin,
    currentUser?.organisations,
  ]);

  const hasOwnerAccess = () => {
    return (
      currentUser?.is_quickcare_admin ||
      currentUser?.organisations[0].access === "owner"
    );
  };

  const hasOwnerOrManagerAccess = () => {
    return (
      hasOwnerAccess() || currentUser?.organisations[0].access === "manager"
    );
  };

  const deactivateAnEducator = async (userId, id) => {
    try {
      if (currentUser?.is_quickcare_admin) {
        await post(
          id === 0
            ? `/admin/educators/qc/deactivate`
            : `/admin/educators/private/deactivate`,
          {
            user_id: userId,
            org_id: id,
          }
        );
        fetchEducatorInfo();
      } else {
        await put(
          `/manage/organisations/${currentUser?.organisations[0].organisation_id}/staff/${userId}`,
          {
            email: educatorInfo.email,
            access: educatorInfo.access,
            deactivated: true,
            centres: educatorInfo.centres.map((item) => item.id),
          }
        );
      }
      setEducatorInfo((prevInfo) => ({
        ...prevInfo,
        deactivated: true,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const reActivateAnEducator = async (userId, id) => {
    try {
      if (currentUser?.is_quickcare_admin) {
        await post(
          id === 0
            ? `/admin/educators/qc/reactivate`
            : `/admin/educators/private/reactivate`,
          {
            user_id: userId,
            org_id: id,
          }
        );
        fetchEducatorInfo();
      } else {
        await put(
          `/manage/organisations/${currentUser?.organisations[0].organisation_id}/staff/${userId}`,
          {
            email: educatorInfo.email,
            access: educatorInfo.access,
            deactivated: false,
            centres: educatorInfo.centres.map((item) => item.id),
          }
        );
        setEducatorInfo((prevInfo) => ({
          ...prevInfo,
          deactivated: false,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateDateFilter = (isStartDate, date) => {
    if (isStartDate && !!endDate && compareTwoDates(date, endDate) === 1) {
      return;
    }
    if (!isStartDate && !!startDate && compareTwoDates(startDate, date) === 1) {
      return;
    }
    _.debounce(() => {
      if (isValidDate(date)) {
        isStartDate ? setStartDate(date) : setEndDate(date);
      }
      if (isValidDate(date) && isValidDate(isStartDate ? endDate : startDate)) {
      }
    }, 500)();
  };

  const handleSubmitPopup = () => {
    if (educatorInfo.deactivated) {
      reActivateAnEducator(educatorInfo.id);
    } else {
      deactivateAnEducator(educatorInfo.id);
    }
  };

  const handleDialogClose = () => {
    setActiveDialogOpen(false);
  };
  const activatedEducator =
    educatorInfo?.organisations?.filter((org) => org?.status === "invited") ??
    [];
  const [rejectDocId, setRejectDocId] = useState(null);
  const rejectQualification = async (document_id, reason, document_type) => {
    try {
      if (document_type === "qualification") {
        await post("/manage/qualification/manual_rejection", {
          document_id,
          reason,
        });
      } else {
        await post("/manage/certificate/manual_rejection", {
          document_id,
          reason,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const baseUrl = "/file-content?filePath=";
  const fetchFile = useCallback(async (url) => {
    try {
      const fileSrc = await getFileAsBlob(`${baseUrl}${url}`);
      setFileSrc(fileSrc);
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  }, []);
  console.log("educatorInfo", educatorInfo);
  useEffect(() => {
    if (educatorInfo?.qualification_url) {
      fetchFile(educatorInfo?.qualification_url);
    }
  }, [fetchFile, educatorInfo?.qualification_url]);
  const fetchEducatorInfo = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const orgUrl = currentUser?.is_quickcare_admin
        ? `/admin/educator/${id}`
        : `/manage/organisations/${currentUser?.organisations[0].organisation_id}/staff/${id}`;
      const res = await get(orgUrl);
      if (!res) {
        navigate("/shifts/error");
      }
      const user = res;
      const identityLink = await loadMemberIdentityLink(user.user_info_id);
      setEducatorInfo({...user, identityLink : identityLink});

      const organisationsActive =
        user.organisations?.filter((org) => org?.status === "invited") ?? [];
      if (user.quickcare_educator) {
        organisationsActive.push({ name: "QC Org", id: 0 });
      }
      const organisationsDeactive =
        user.organisations?.filter((org) => org?.status === "deactivated") ??
        [];
      if (!user.quickcare_educator && user.deactivated_quickcare_date) {
        organisationsDeactive.push({ name: "QC Org", id: 0 });
      }
      setOrganisationsActive(organisationsActive);
      setOrganisationsDeactive(organisationsDeactive);
    } catch (e) {
      navigate("/shifts/error");
    }
    setIsLoading(false);
  };


  const loadMemberIdentityLink = async (member_id) => {
    try {
      const identityLink = await get(`/admin/onboarding/identity/${member_id}`);
      return identityLink;
    } catch (error) {
      return null;
    }
  };
  useEffect(() => {
    fetchEducatorInfo();
  }, []);
  useEffect(() => {
    fetchShift();
  }, [fetchShift]);
  const tabValues = [
    { name: "Assigned shifts", value: 0 },
    { name: "Profile", value: 1 },
    { name: "Documents", value: 2 },
  ];
  const isProfileTab = currentTab === 1;
  const isCertificateTab = currentTab === 2;
  const isShiftTab = currentTab === 0;
  return (
    <div>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
        >
          {" "}
          <CircularProgress />{" "}
        </Box>
      ) : (
        <Box>
          <ManualRejectionModal
            open={manualRejectionModalOpen}
            onClose={() => setManualRejectionModalOpen(false)}
            onReject={(reason) => {
              rejectQualification(rejectDocId, reason, "qualification");
            }}
          />
          <ManualVerifyModalQualification
            open={manualModalOpen}
            onClose={(preventRefresh) => {
              setManualModalOpen(false);
            }}
            rejectQualification={(document_id) => {
              setRejectDocId(document_id);
              setManualRejectionModalOpen(true);
            }}
            document={educatorInfo?.qualification_document}
            personID={educatorInfo?.id}
            isUpdate={true}
            fileUrl={educatorInfo?.qualification_url}
            fileSrc={fileSrc}
          />
          <Grid container item xs={12} spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <BackButton
                title={"Back to educators"}
                onClick={() => {
                  navigate("/educators");
                }}
              ></BackButton>
              <PaddingBox />
              {educatorInfo &&
              educatorInfo.quickcare_educator &&
              currentUser?.is_quickcare_admin ? (
                <Tags type={quickcareEducator}></Tags>
              ) : null}
              {educatorInfo && activatedEducator?.length > 0 ? (
                <Box>
                  <Row
                    sx={{
                      marginTop: "8px",
                      alignItems: "start",
                      padding: "8px",
                      borderRadius: "8px",
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.secondary.onSecondary,
                      width: "fit-content",
                    }}
                  >
                    <M3TitleMedium>Private educator for</M3TitleMedium>
                    <PaddingBox />
                    <Box>
                      {activatedEducator.map((organisation) => {
                        return (
                          <Row sx={{ paddingBottom: "8px" }}>
                            <CustomAvatar
                              src={organisation.logo_url}
                              sx={{
                                height: "16px",
                                width: "16px",
                                paddingRight: "8px",
                              }}
                            />
                            <M3BodyMedium>{organisation.name}</M3BodyMedium>
                          </Row>
                        );
                      })}
                    </Box>
                    <PaddingBox />
                  </Row>
                </Box>
              ) : null}
              <PaddingBox />

              <Tags type={decideTagType(educatorInfo)}></Tags>
              <PaddingBox />
              <ListItem
                alignItems="flex-start"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ListItemAvatar>
                  <CustomAvatar
                    src={
                      educatorInfo && educatorInfo.avatar_url !== null
                        ? educatorInfo.avatar_url
                        : null
                    }
                  />
                </ListItemAvatar>
                <SpacedRow>
                  <Space direction="vertical" gap={0}>
                    <ListItemText
                      primary={
                        <M3TitleLarge>
                          {educatorInfo
                            ? educatorInfo.first_name +
                              " " +
                              educatorInfo.last_name
                            : ""}
                        </M3TitleLarge>
                      }
                    />
                  </Space>
                  {currentTab === 0 ? (
                    <ListItemSecondaryAction>
                      {educatorInfo &&
                      educatorInfo.email &&
                      hasOwnerAccess() &&
                      currentUser?.is_quickcare_admin ? (
                        <Row>
                          <DeactiveAndActiveButton
                            text={"Reactivate"}
                            values={organisationsDeactive}
                            onClick={(id) => {
                              reActivateAnEducator(educatorInfo.id, id);
                            }}
                          />
                          <DeactiveAndActiveButton
                            text={"Deactivate"}
                            values={organisationsActive}
                            onClick={(id) => {
                              deactivateAnEducator(educatorInfo.id, id);
                            }}
                          ></DeactiveAndActiveButton>
                        </Row>
                      ) : null}
                      {educatorInfo &&
                      educatorInfo.email &&
                      hasOwnerAccess() &&
                      !currentUser?.is_quickcare_admin ? (
                        educatorInfo.deactivated ? (
                          <SuccessButton
                            text="Reactivate"
                            onClick={() => setActiveDialogOpen(true)}
                          />
                        ) : (
                          <RemoveButton
                            text="Deactivate"
                            onClick={() => setActiveDialogOpen(true)}
                          />
                        )
                      ) : null}
                    </ListItemSecondaryAction>
                  ) : (
                    hasOwnerOrManagerAccess() &&
                    currentTab === 1 && (
                      <ListItemSecondaryAction>
                        <PrimaryButton
                          text="Update Qualification"
                          onClick={handleOpenPopup}
                        />
                      </ListItemSecondaryAction>
                    )
                  )}
                </SpacedRow>
              </ListItem>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: theme.palette.outline.main,
                }}
              >
                {currentUser?.is_quickcare_admin && (
                  <Space
                    gap={"8px"}
                    style={{ alignItems: "center", marginLeft: "25px" }}
                  >
                    <RatingIcon sx={{ fontSize: 24 }} />
                    <M3TitleLarge
                      sx={{ color: theme.palette.text.main, fontWeight: 400 }}
                    >
                      {`Rating ${educatorInfo?.rating || "0"}%`}
                    </M3TitleLarge>
                  </Space>
                )}
                <CustomTabs
                  variant="fullWidth"
                  value={currentTab}
                  onChange={handleTabChange}
                  indicatorColor="info"
                >
                  {tabValues.map((tab) => {
                    return <CustomTab label={tab.name} key={tab.value} />;
                  })}
                </CustomTabs>
              </Box>
              {isShiftTab && (
                <Box sx={{ p: 3 }}>
                  <SpacedRow>
                    <Row>
                      <Row>
                        <DatePickerTextField
                          label={"Start date"}
                          maxDate={endDate === "" ? getMaxDate() : endDate}
                          minDate={getMinDate()}
                          onChange={(event) => {
                            updateDateFilter(true, event.target.value);
                          }}
                          value={startDate}
                        ></DatePickerTextField>
                        <PaddingBox />
                        <DatePickerTextField
                          label={"End date"}
                          maxDate={getMaxDate()}
                          minDate={startDate === "" ? getMinDate() : startDate}
                          onChange={(event) => {
                            updateDateFilter(false, event.target.value);
                          }}
                          value={endDate}
                        ></DatePickerTextField>
                      </Row>
                    </Row>
                    <PaddingBox />
                  </SpacedRow>
                </Box>
              )}
              <PaddingBox />
              {isShiftTab ? (
                <List>
                  {shifts &&
                    shifts.map((shiftIndex, index) => (
                      <div key={shiftIndex.id}>
                        <EducatorShiftItemList
                          onClick={() => {
                            navigateToShiftDetail(shiftIndex.id);
                          }}
                          params={{
                            shift: shiftIndex,
                          }}
                        ></EducatorShiftItemList>
                      </div>
                    ))}
                </List>
              ) : educatorInfo && isProfileTab ? (
                <>
                  <PaddingBox />
                  <InfoProfileUser
                    member={educatorInfo}
                    showInviteButton={false}
                  />
                </>
              ) : isCertificateTab ? (
                <CertTab
                  member={educatorInfo}
                  user_info_id={educatorInfo.user_info_id}
                  refresh={fetchEducatorInfo}
                  person_id={educatorInfo.id}
                />
              ) : null}

              {educatorInfo && (
                <ErrorDialogModal
                  open={activeDialogOpen}
                  onClose={handleDialogClose}
                  title={`Are you sure you want to ${
                    educatorInfo.deactivated ? "reactivate " : "deactivated"
                  } this educator?`}
                  onClickCTA={handleSubmitPopup}
                  content={`The educator will receive a notification of this ${
                    educatorInfo.deactivated ? "reactivation " : "deactivation"
                  }, 
              and will be ${
                educatorInfo.deactivated ? "able " : "unable"
              } to access your shifts.`}
                  ctaTitle={"Yes"}
                ></ErrorDialogModal>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};
export default EducatorDetail;
