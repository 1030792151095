import {
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { get, post, postFormData } from "../../../../../utils/api";
import { Row } from "../../../../Layout";
import theme from "../../../../../utils/theme";
import { ReactComponent as AddDocumentLogo } from "../../../../../assets/document-logo.svg";
import { ReactComponent as GOVLogo } from "../../../../../assets/gov-logo.svg";
import { ReactComponent as DeleteLogo } from "../../../../../assets/delete-logo.svg";
import {
  CommonTextField,
  DatePickerTextField,
} from "../../../../reusable/TextField";
import { PaddingBox } from "../../../../reusable/Scaffolds";
import { qualificationLevelId } from "../../../../../utils/constants";
import { OrgContext, UserContext } from "../../../../../utils/context";
import { isOwner } from '../../../../../utils/auth';
import { FullSizedButton } from "../../../../reusable/Buttons";
import { CommonLayoutModal } from "../../../../reusable/modals/common_modal_layout";
import { MileStoneRow, QualificationItem } from "../../../../reusable/QualificationItem";
import { CommonSwitch, CommonSwitchLabel } from "../../../../reusable/inputs/switches";
const previewStyle = {
  borderRadius: 10,
  width: "100%",
  height: "110px",
  textAlign: "left",
  objectFit: "cover",
};


export function ManualVerifyModalQualification({
  open,
  onClose,
  document,
  fileSrc,
  fileUrl,
  personID,
  rejectQualification,
  isUpdate
}) {
  const fileNameInput = fileUrl?.split("/").pop();
  const [uploadingFile, setUploadingFile] = useState(false);
  const defaultDate = new Date();
  const defaultDateStr = `${defaultDate.getFullYear()}-${defaultDate.getMonth() + 1}-${defaultDate.getDate()}`;
  const [documentDate, setDocumentDate] = useState(defaultDateStr);
  const [selectedQualificationId, setSelectedQualificationId] = useState(null);
  const [passedMilestones, setPassedMilestones] = useState(null);
  const [qualificationsList, setQualificationsList] = useState([]);
  const [rtoNumber, setRtoNumber] = useState("");
  const [deemedQualificationDiploma, setDeemedQualificationDiploma] = useState(false);
  const currentUser = useContext(UserContext)
  const orgId = currentUser?.is_quickcare_admin? 0: currentUser?.organisations[0].organisation_id
  const closeAndReset = (preventRefresh) => {
    onClose(preventRefresh);
    setDocumentDate("");
    setPassedMilestones(false);
  };
  const getUserQualification = useCallback(async () => {
    try {
      if (!personID || !open) {
        return;
      }
      const res = await get("/manage/qualification-detail", null, {
        educator_id: personID,
        org_id: orgId
      });
      setPassedMilestones(res?.milestone_passed);
      setRtoNumber(res?.rto_number);
      setDocumentDate(res?.latest_qualification_date);
      setDeemedQualificationDiploma(res?.deemed_qualification);
      qualificationsList.map((qualification) => {
        if (res.qualification === qualification?.id) {
          qualification.selected = true;
          setSelectedQualificationId(res.qualification);
        }
        return qualification;
      });
      setQualificationsList(qualificationsList);
    } catch (error) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personID, open]);
  const updateQualificationFile = async (updatedFile, _ , docType) => {
    if (!updatedFile) {
      return;
    }
    setUploadingFile(true);
    const fileName = `${docType}.${new Date().getTime()}.${updatedFile.name
      .split(".")
      .pop()}`;
    const formData = new FormData();
    formData.append('file', updatedFile);
    try {
      const response = await postFormData(`/onboarding-upload?fileName=${fileName}&educator_id=${personID}&org_id=${orgId}`, formData,
        {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        }
      );
      setUploadingFile(false);
      return response
    } catch (error) {
      setUploadingFile(false);
    }
  }
  const fetchQualifications = async () => {
    try {
      const orgData = await get("/manage/qualifications", null, {
        is_profile: true,
      });
      const qualificationMapped = orgData.qualifications
        .filter(
          (item) =>
            item.id !== qualificationLevelId.ANY &&
            item.id !== qualificationLevelId.UNQUALIFIED &&
            item.id !== qualificationLevelId.CERT_AND_DIPLOMA
        )
        .map((qualification) => {
          qualification.value = qualification.id;
          qualification.name = qualification.qualification;
          // replace Working towards with WT
          qualification.name = qualification.name.replace(
            "Working towards",
            "WT"
          );
          return qualification;
        });
      // Re aranage the qualification list in order of item with id % 2 === 0 will be shown later in the list
      qualificationMapped.sort((a, b) => {
        if (a.id % 2 === 0 && b.id % 2 !== 0) {
          return 1;
        } else {
          return -1;
        }
      });
      // put in the 4th in the list
      const unQualifiedQualification = {
        id: qualificationLevelId.UNQUALIFIED,
        name: "Unqualified",
        value: qualificationLevelId.UNQUALIFIED,
      };
      qualificationMapped.splice(0, 0, unQualifiedQualification);
      setQualificationsList(qualificationMapped);
    } catch (error) {
      console.error(error);
    }
  };

  const verifyQualification = async () => {
    try {
      await post("/manage/qualification/manual_verification", {
        qualification_id: selectedQualificationId,
        enrollment_date: documentDate,
        milestone_passed: passedMilestones,
        deemed_qualification: deemedQualificationDiploma
          ? qualificationLevelId.DIPLOMA
          : null,
        rto_number: rtoNumber,
        educator_id: personID,
        org_id: orgId,
        is_update: isUpdate,
      });
      closeAndReset();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchQualifications();
  }, []);

  useEffect(() => {
    getUserQualification();
  }, [getUserQualification]);

 
  const shouldDisableVerifyButton =
    !documentDate || (!selectedQualificationId && selectedQualificationId !== 0) || !rtoNumber;

  return (
    <CommonLayoutModal
      open={open}
      onClose={onClose}
      title={"Update Qualification"}
    >
      {!!document &&
        document?.verified_status !== "success" &&
        document?.verified_status !== "verifying" && (
          <Chip
            sx={{
              mb: 2,
              backgroundColor: theme.palette.error.main,
              color: "white",
            }}
            label={document?.message}
            icon={<ErrorIcon sx={{ fill: "white" }} />}
          ></Chip>
        )}
      <FileSection
        fileSrc={fileSrc}
        fileNameInput={fileNameInput}
        uploadingFile={uploadingFile}
        updateFile={updateQualificationFile}
        fileUrl={fileUrl}
      ></FileSection>
      <Divider />

      <TitleItem title={"RTO Number"}></TitleItem>
      <RTOTextField
        title={"Enter RTO Number"}
        value={rtoNumber}
        onChange={(e) => {
          setRtoNumber(e.target.value);
        }}
      ></RTOTextField>
      {[
        qualificationsList.slice(0, 4).reverse(),
        qualificationsList.slice(4).reverse(),
      ].map((list) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: "10px",
          }}
        >
          {list.map((item) => (
            <QualificationItem
              item={item}
              selected={item.selected}
              onClick={() => {
                const updatedQualificationsList = qualificationsList.map(
                  (qualification) => {
                    if (qualification.id === item.id) {
                      qualification.selected = !qualification.selected;
                    } else {
                      qualification.selected = false;
                    }
                    return qualification;
                  }
                );
                const selectedQualification = updatedQualificationsList.find(
                  (qualification) => qualification.selected
                );
                setQualificationsList(updatedQualificationsList);
                setSelectedQualificationId(selectedQualification?.id);
              }}
            ></QualificationItem>
          ))}
        </div>
      ))}
      <PaddingBox></PaddingBox>
      <TitleItem title={"Qualification/Enrollment Date"}></TitleItem>
      <PaddingBox></PaddingBox>
      <DatePickerTextField
        value={documentDate}
        onChange={(e) => setDocumentDate(e.target.value)}
        disableWeekend={true}
      ></DatePickerTextField>

      {selectedQualificationId === qualificationLevelId.ECT_TOWARRD && (
        <MileStoneRow
          passedMilestone={passedMilestones}
          onChange={(e) => {
            setPassedMilestones(e);
          }}
        ></MileStoneRow>
      )}
      <PaddingBox />
      {selectedQualificationId === qualificationLevelId.CERT3 && (
        <div>
          <Row>
            <CommonSwitch
              checked={deemedQualificationDiploma}
              onChange={(e) => setDeemedQualificationDiploma(e.target.checked)}
            />
            <CommonSwitchLabel title={"Deemed at Diploma Level"} />
          </Row>
          <Typography
            sx={{
              fontSize: "16px",
              textAlign: "left",
              marginTop: "10px",
              fontWeight: "400",
            }}
          >
            Sets award 3.4 level on accepted shifts.
          </Typography>
        </div>
      )}

      <ActionButtonRow
        onVerify={verifyQualification}
        disabledVerifyButton={shouldDisableVerifyButton}
        onCancel={() => closeAndReset()}
        disabledDeleteButton={!document}
        onDelete={() => {
          closeAndReset(true);
          rejectQualification(document?.id);
        }}
      ></ActionButtonRow>
    </CommonLayoutModal>
  );
}
export function ManualRejectionModal({ open, onClose, onReject }) {
  const [reason, setReason] = useState("");
  const isDisabledRejectButton = !reason;
  return (
    <CommonLayoutModal
      open={open}
      onClose={onClose}
      title={"Confirmation"}
    >
      <Typography
        sx={{ mt: 1, fontSize: "24px", fontWeight: "500", textAlign: "left" }}
      >
        Are you sure you want to reject this document?
      </Typography>
      <Typography sx={{ fontSize: "14px", textAlign: "left" }}>
        If you reject this document, the reason below will be shown to the
        educator.
      </Typography>
      <PaddingBox></PaddingBox>
      <TitleItem title={"Rejection Reason"}></TitleItem>
      <CommonTextField
        value={reason}
        isMultiline={true}
        onChange={(e) => setReason(e.target.value)}
      ></CommonTextField>
      <PaddingBox></PaddingBox>
      <RejectionActionButtonRow
        disabledReject={isDisabledRejectButton}
        onReject={() => {
          onReject(reason);
          onClose();
        }}
        onCancel={onClose}
      ></RejectionActionButtonRow>
    </CommonLayoutModal>
  );
}
export function ManualVerifyModalOthers({
  open,
  onClose,
  document,
  fileSrc,
  fileUrl,
  personID,
  userInfoId,
  rejectDocument,
}) {
  const updateTitle =
    document?.document_type === "food_handling_cert"
      ? "Update Food Handling Certificate"
      : document?.document_type === "resume"
      ? "Update Resume"
      : document?.document_type === "transcript"
      ? "Update Transcript"
      : document?.document_type === "previous_qualification"
      ? "Update Previous Qualification"
      : "Verify Document";
  const fileNameInput = fileUrl?.split("/").pop();
  const [rtoNumber, setRtoNumber] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);
  const [qualificationsList, setQualificationsList] = useState([]);
  const [selectedQualificationId, setSelectedQualificationId] = useState(null);
  const [documentDate, setDocumentDate] = useState("");
  const fileNameInputExtracted = fileNameInput?.split(".")[0];
  const currentUser = useContext(UserContext)
  const orgId = currentUser?.is_quickcare_admin? 0: currentUser?.organisations[0].organisation_id
  const closeAndReset = (preventRefresh) => {
    onClose(preventRefresh);
    setDocumentDate("");
  };
  const fetchQualifications = async () => {
    try {
      const orgData = await get("/manage/qualifications", null, {
        is_profile: true,
      });
      const qualificationMapped = orgData.qualifications
        .filter(
          (item) =>
            item.id !== qualificationLevelId.ANY &&
            item.id !== qualificationLevelId.UNQUALIFIED
        )
        .map((qualification) => {
          qualification.value = qualification.id;
          qualification.name = qualification.qualification;
          // replace Working towards with WT
          qualification.name = qualification.name.replace(
            "Working towards",
            "WT"
          );
          return qualification;
        });
      // Re aranage the qualification list in order of item with id % 2 === 0 will be shown later in the list
      qualificationMapped.sort((a, b) => {
        if (a.id % 2 === 0 && b.id % 2 !== 0) {
          return 1;
        } else {
          return -1;
        }
      });
      // put in the 4th in the list
      const unQualifiedQualification = {
        id: qualificationLevelId.UNQUALIFIED,
        name: "Unqualified",
        value: qualificationLevelId.UNQUALIFIED,
      };
      qualificationMapped.splice(0, 0, unQualifiedQualification);
      setQualificationsList(qualificationMapped);
    } catch (error) {
      console.error(error);
    }
  };
  const getCertificateInfo = async () => {
    if (!personID || !open || !fileNameInputExtracted) {
      return;
    }
    try {
      const res = await get("/manage/certificate", null, {
        educator_id: personID,
        user_info_id: userInfoId,
        org_id: orgId,
        type: fileNameInputExtracted,
      });
      setRtoNumber(res?.rto_number);
      setDocumentDate(res?.expiry_date);
      console.log(res);
      qualificationsList.map((qualification) => {
        if (res.previous_qualification === qualification?.id) {
          qualification.selected = true;
          setSelectedQualificationId(res.qualification);
        }
        return qualification;
      });
      setQualificationsList(qualificationsList);
    } catch (error) {
      console.error(error);
    }
  };
  const showOtherFields =
    document?.document_type !== "resume" &&
    document?.document_type !== "transcript" &&
    document?.document_type !== "previous_qualification";

  const notShowingFields = 
    document?.document_type === "other" ||
    document?.document_type === "teacher_registration"
  const verifyQualification = async () => {
    try {
      if (showOtherFields){
        await post("/manage/certificate/manual_verification", {
          expiry_date: documentDate,
          rto_number: rtoNumber,
          org_id: orgId,
          educator_id: personID,
          document_type: document?.document_type,
        });
      }
      else {
        await post("/manage/others/manual_verification", {
          educator_id: personID,
          document_type: document?.document_type,
          org_id: orgId,
          previous_qualification_id: selectedQualificationId,
        });
      }
      closeAndReset();
    } catch (error) {
      console.error(error);
    }
  };

  const updateQualificationFile = async (updatedFile, _ , docType) => {
    if (!updatedFile) {
      return;
    }
    setUploadingFile(true);
    const fileName = `${docType}.${new Date().getTime()}.${updatedFile.name
      .split(".")
      .pop()}`;

    const formData = new FormData();
    formData.append('file', updatedFile);
    try {
      const response = await postFormData(`/onboarding-upload?fileName=${fileName}&educator_id=${personID}&org_id=${orgId}`, formData,
        {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        }
      );
      setUploadingFile(false);
      return response
    } catch (error) {
      setUploadingFile(false);
      throw error;
    }
  }

  useEffect(() => {
    getCertificateInfo();
    fetchQualifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const isOtherCertDocument = document?.document_type === 'food_handling_cert' || document?.document_type === 'child_protection_cert'; 
  const isDisableSaveButton = notShowingFields
    ? false
    : isOtherCertDocument
    ? !documentDate
    : (!rtoNumber || !documentDate) && showOtherFields;
  return (
    <CommonLayoutModal
      open={open}
      onClose={onClose}
      title={updateTitle ?? "Update Certificate"}
    >
      {!!fileUrl && (
        <FileSection
          fileSrc={fileSrc}
          fileNameInput={fileNameInput}
          fileUrl={fileUrl}
          uploadingFile={uploadingFile}
          updateFile={(updatedFile, fileName) =>
            updateQualificationFile(
              updatedFile,
              fileName,
              document?.document_type
            )
          }
        ></FileSection>
      )}
      <Divider />

      {(showOtherFields && !isOtherCertDocument && !notShowingFields) && (
        <>
          <TitleItem title={"RTO Number"}></TitleItem>
          <RTOTextField
            value={rtoNumber}
            onChange={(e) => {
              setRtoNumber(e.target.value);
            }}
          ></RTOTextField>
        </>
      )}
      {(showOtherFields && !notShowingFields) && (
        <>
          <TitleItem title={"Expiry Date"}></TitleItem>
          <DatePickerTextField
            value={documentDate}
            disable={document?.document_type === "resume"}
            onChange={(e) => setDocumentDate(e.target.value)}
            disableWeekend={true}
          ></DatePickerTextField>
        </>
      )}
      {document?.document_type === "previous_qualification" &&
        [
          qualificationsList.slice(0, 4).reverse(),
          qualificationsList.slice(4).reverse(),
        ].map((list) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: "10px",
            }}
          >
            {list.map((item) => (
              <QualificationItem
                item={item}
                selected={item.selected}
                onClick={() => {
                  const updatedQualificationsList = qualificationsList.map(
                    (qualification) => {
                      if (qualification.id === item.id) {
                        qualification.selected = !qualification.selected;
                      } else {
                        qualification.selected = false;
                      }
                      return qualification;
                    }
                  );
                  const selectedQualification = updatedQualificationsList.find(
                    (qualification) => qualification.selected
                  );
                  setQualificationsList(updatedQualificationsList);
                  setSelectedQualificationId(selectedQualification?.id);
                }}
              ></QualificationItem>
            ))}
          </div>
        ))}
      <ActionButtonRow
        onVerify={verifyQualification}
        disabledVerifyButton={isDisableSaveButton}
        onCancel={() => closeAndReset()}
        onDelete={() => {
          closeAndReset(true);
          rejectDocument(document?.id);
        }}
      ></ActionButtonRow>
    </CommonLayoutModal>
  );
}
function RejectionActionButtonRow({ onReject, onCancel, disabledReject }) {
  return (
    <Row>
      <FullSizedButton text="Cancel" type={"secondary"} onClick={onCancel} />
      <PaddingBox></PaddingBox>
      <FullSizedButton
        disabled={disabledReject}
        text="Reject"
        type={"tertiary"}
        onClick={onReject}
      />
    </Row>
  );
}
function ActionButtonRow({
  onVerify,
  onCancel,
  onDelete,
  disabledVerifyButton,
  disabledDeleteButton,
}) {
  const currentUser = useContext(UserContext)
  const currentOrg = useContext(OrgContext)
  const canSave = currentUser?.is_quickcare_admin || isOwner(currentOrg)
  return (
    <Row>
     { 
     canSave &&
      <FullSizedButton
        text="Manually Set"
        type={"primary"}
        disabled={disabledVerifyButton}
        onClick={onVerify}
      />}
      <PaddingBox></PaddingBox>
      <FullSizedButton text="Close" type={"secondary"} onClick={onCancel} />
      <PaddingBox></PaddingBox>
      <FullSizedButton
        text=""
        dense={true}
        disabled={disabledDeleteButton}
        type={"tertiary"}
        icon={<DeleteLogo />}
        onClick={onDelete}
      />
    </Row>
  );
}
export function RTOTextField({ value, onChange, title }) {
  return (
    <FormControl sx={{ width: "100%" }}>
      <OutlinedInput
        value={value}
        placeholder={title}
        onChange={onChange}
        variant="contained"
        startAdornment={
          <InputAdornment position="start">
            <GOVLogo
              style={{
                width: "20px",
                height: "20px",
                fill: theme.palette.primary.main,
              }}
            />
          </InputAdornment>
        }
        fullWidth
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          marginTop: 2,
        }}
      ></OutlinedInput>
    </FormControl>
  );
}

function TitleItem({ title }) {
  return (
    <Typography
      sx={{
        mt: 1,
        fontSize: "14px",
        textAlign: "left",
        fontWeight: "bold",
      }}
    >
      {title}
    </Typography>
  );
}



function FileSection({ fileSrc, fileNameInput, uploadingFile, updateFile, fileUrl }) {
  const [fileName, setFileName] = useState(fileNameInput);
  const [updatedFile, setUpdatedFile] = useState(null);
  const fileNameInputExtracted = fileNameInput?.split(".")[0] ?? 'qualification';
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const currentTimestamp = new Date().getTime();
    const updatedFileName = `${fileNameInputExtracted}.${currentTimestamp}.${file.name
      .split(".")
      .pop()}`;
    setUpdatedFile(file);
    setFileName(updatedFileName);
    updateFile(file, updatedFileName, fileNameInputExtracted);
  };  
  const currentUser = useContext(UserContext)
  const currentOrg = useContext(OrgContext)
  const canSave = currentUser?.is_quickcare_admin || isOwner(currentOrg)
  const fileInputRef = useRef(null);
  return (
    <>
      {!(!updatedFile && !fileUrl) && (
        <Row>
          {uploadingFile ? (
            <CircularProgress
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          ) : fileName?.toLowerCase().endsWith("pdf") ? (
            <Worker workerUrl="/pdf.worker.min.js">
              <Viewer
                fileUrl={
                  updatedFile ? URL.createObjectURL(updatedFile) : fileSrc
                }
                initialPage={0}
              />
            </Worker>
          ) : (
            <img
              src={updatedFile ? URL.createObjectURL(updatedFile) : fileSrc}
              alt={""}
              style={previewStyle}
            />
          )}
        </Row>
      )}
      {canSave && (
        <>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <FullSizedButton
            text={`Update Document`}
            icon={<AddDocumentLogo />}
            type={"secondary"}
            onClick={() => {
              fileInputRef.current.click();
            }}
          />
        </>
      )}
    </>
  );
}
